$section: '.branding';

#{$section} {

  padding-bottom: 3em;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    @include mq($until: mobile) {
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: 50%;

    @include mq($until: mobile) {
      flex-direction: column;
      width: 100%;
      text-align: center;
      padding-bottom: 3em;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 50%;
    text-align: center;
    justify-content: center;

    @include mq($until: mobile) {
      width: 100%;
    }
  }

  &__appicon {
    width: 100%;
    max-width: calc(32% - 7.5px);
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 4em;

    @include mq($until: tablet) {
      padding-left: 0;
    }

    @include mq($until: mobile) {
      max-width: 100%;
    }
  }

  &__appicon-image {
    width: 140px;
    height: auto;

    @include mq($until: mobile) {
      margin: 0 auto;
    }
  }

  &__appinfo {
    width: 100%;
    max-width: calc(69% - 7.5px);
    justify-content: center;
    display: flex;
    flex-direction: column;

    @include mq($until: tablet) {
      max-width: calc(63% - 7.5px);
    }

    @include mq($until: mobile) {
      max-width: 100%;
    }
  }

  &__app-title {
    font-size: 26px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: $primaryColor;
    margin: 0;
    padding-bottom: 0.3em;

    @include mq($until: mobile) {
      font-size: 20px;
    }

  }

  &__app-description {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $slimeGreen;
    margin: 0;

    @include mq($until: mobile) {
      font-size: 18px;
    }

  }

  &__divider {
    height: 200px;
    border: solid 2px $lightBlue;

    @include mq($until: mobile) {
      display: none;
    }
  }

  &__app-link {
    margin: 0.5em 6.5em;
    padding: 1em 0;
    background-color: $pelaGray;
    border-radius: 1em;

    @include mq($until: tablet) {
      margin: 0.5em 5.5em;
    }

    @include mq($until: mobile) {
      margin: 0.5em 4.5em;
    }
  }

  &__app-image {
    @include mq($until: mobile) {
      width: 164.9px;
      height: auto;
    }
  }

}