$section: '.home-header';

#{$section} {
  background: linear-gradient(175deg, rgba(241,246,248,1) 0%,rgba(241,246,248,1) 78%,rgba(241,246,248,1) 77%,rgba(241,246,248,1) 77%,rgba(255,255,255,1) 78%,rgba(255,255,255,1) 100%);
  padding-top: 2em;;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    @include mq($until: mobile) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding-left: 6.5em;
    padding-top: 6rem;
    justify-content: flex-start;
    width: 38%;

    @include mq($until: mobile) {
      padding-top: 0;
      padding-left: 0;
      width: 100%;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 62%;
    max-width: 100%;
    margin-right: 5em;

    @include mq($until: tablet) {
      margin-right: 0;
    }

    @include mq($until: mobile) {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }
  }

  &__logo {
    @include mq($until: mobile) {
      text-align: center;
    }
  }

  &__logo-image {
    @include mq($until: mobile) {
      width: 150px;
      height: auto;
    }
  }

  &__description {
    font-size: 26px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: normal;
    color: $primaryColor;
    margin-top: 0;

    @include mq($until: tablet) {
      font-size: 1.7em;
    }

    @include mq($until: mobile) {
      font-size: 20px;
      text-align: center;
    }

    b {
      color: $slimeGreen;
      font-weight: normal;
    }
  }

  &__apps {

    @include mq($until: btablet) {
      text-align: center;
    }

    @include mq($until: tablet) {
      text-align: center;
    }

    @include mq($until: mobile) {
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  &__app-link {
    background-color: $white;
    border-radius: 10px;
    display: inline-flex;
    justify-content: center;
    padding: 0.5rem;

    &:first-child {
      margin-right: 1rem;

      @include mq($until: btablet) {
        margin-bottom: 1rem;
        margin-right: 0.3rem;
      }

      @include mq($until: tablet) {
        margin-right: 0.5rem;
        margin-bottom: 1rem;
      }
    }

    @include mq($until: mobile) {
      padding: 0 0.5rem;
    }

    @include mq($until: smobile) {
      padding: 0rem;
    }
  }

  &__app-image {
    height: auto;
    max-width: 144px;

    @include mq($until: mobile) {
      max-width: 140px;
    }
  }

  &__illustration {
    width: 530px;
    height: auto;

    @include mq($until: tablet) {
      width: 430px;
    }

    @include mq($until: mobile) {
      width: 279px;
      margin: 0 auto;
    }
  }

}