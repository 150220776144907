// Breakpoints
$mq-breakpoints: (
    smobile: 340px,
    xmobile:  640px,
    mobile:  767px,
    tablet: 992px,
    btablet: 1076px,
    large: 1200px,
    wide: 1319.5px,
);

// Size for containers
$containerMaxWidth: 1320px;

// Colors
$primaryColor:  #001e34 !default;
$slimeGreen:    #91cf00 !default;
$iris:          #5c6cb4 !default;
$lightBlue:     #e8edf3 !default;
$pelaGray:      #f6fafb !default;
$white:         #ffffff !default;

// Fonts
$primaryf: 'Assistant', sans-serif;

