$section: '.footer';

#{$section} {
  padding-top: 2em;
  padding-bottom: 1.5em;
  border: solid 1px $lightBlue;
  background-color: $pelaGray;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    @include mq($until: mobile) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__logo {
    padding-left: 2em;

    @include mq($until: tablet) {
      padding-left: 1em;
    }

    @include mq($until: mobile) {
      padding-left: 0;
      text-align: center;
    }
  }

  &__logo-image {
    width: 160px;
    height: auto;
  }

  &__menu {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &__social {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-right: 2em;
    text-align: center;

    @include mq($until: tablet) {
      padding-right: 1em;
    }
  }

  &__menu-list {
    list-style: none;
    padding-left: 0;

    @include mq($until: mobile) {
      text-align: center;
    }
  }

  &__social-list {
    list-style: none;
    padding-left: 0;
  }

  &__menu-link {
    text-decoration: none;
    color: $primaryColor;

    &:hover {
      color: $slimeGreen;
      text-decoration: underline;
      transition: all ease-out 0.2s;
    }
  }

  &__menu-item {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 0 4em;

    @include mq($until: tablet) {
      padding: 0 1em;
    }

    @include mq($until: mobile) {
      padding: 1em 2em;
    }
  }

  &__social-item {
    display: inline-block;
    padding: 0 1em;
    text-align: center;

    @include mq($until: tablet) {
      padding: 0 0.5em;
    }
  }

  &__social-link {
    &:hover {
      #{$section}__social-icon {
        display: none;
      }

      #{$section}__social-icon--hover {
        display: block;
      }
    }
  }

  &__social-icon {
    &--hover {
      display: none;
    }
  }

}