$section: '.page-header';

#{$section} {

  border: solid 1px $lightBlue;
  background-color: $pelaGray;

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__bar {
    padding-top: 0.2em;

    @include mq($until: mobile) {
      text-align: center;
    }
  }

  &__logo {
    @include mq($until: tablet) {
      padding-left: 0.5em;
    }
  }

}