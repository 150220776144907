* {
    font-family: $primaryf;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.container {
  max-width: 1080px;
  width: 100%;
  margin:  0 auto;

  @include mq($until: mobile) {
    max-width: 100%;
    padding: 0;
  }
}

.row {
  max-width: 100%;

  @include mq($until: tablet) {
    padding: 0 1rem;
  }

  @include mq($until: mobile) {
    padding: 0 0.6em;
  }
}