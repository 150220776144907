$section: '.page-content';

#{$section} {
  padding-bottom: 3em;

  &__row {
    display: flex;
    flex-direction: column;
  }

  &__bar {
    padding-top: 1.3em;

    @include mq($until: tablet) {
      padding-left: 0.5em;
    }
  }

  &__back-link {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2em;
    letter-spacing: normal;
    color: $primaryColor;
    text-decoration: none;

    &:before {
      content: "";
      display: block;
      background: url("../svg/arrow.svg") no-repeat;
      width: 23px;
      height: 23px;
      float: left;
      margin: 0 0.7em 0 0;
    }
  }

  &__content {
    padding: 0 5.5em;

    @include mq($until: tablet) {
      padding: 0 4.5em;
    }

    @include mq($until: mobile) {
      padding: 0 2.5em;
    }
  }

  &__title {
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.74em;
    letter-spacing: normal;
    text-align: center;
    color: $primaryColor;
    margin: 1.5em 0;
  }

  &__paragraph {
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26em;
    letter-spacing: normal;
    color: $primaryColor;
    margin-top: 0;
  }

  &__subtitle {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26em;
    letter-spacing: normal;
    color: $iris;
    margin-bottom: 0;
  }

  &__list {
    list-style-type: circle;
  }

}