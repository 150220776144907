$section: '.features';

#{$section} {
  padding-top: 3em;
  padding-bottom: 5em;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;

    @include mq($until: mobile) {
      flex-direction: column;
    }
  }

  &__item {
    width: calc((100% / 3) - 10px);
    text-align: center;

    @include mq($until: mobile) {
      width: 100%;
      padding-bottom: 1em;
    }
  }

  &__icon {
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $iris;
  }

  &__description {
    font-size: 19px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.26;
    letter-spacing: normal;
    color: $primaryColor;

    b {
      font-weight: 600;
    }
  }

  &__divider {
    width: 100%;
    border: solid 2px $lightBlue;
    display: none;
    margin-top: 3.5em;

    @include mq($until: mobile) {
      display: inline-block;
    }
  }
}